html {
    background: $brown-background;
    font-size: 62.5%;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-size: large;
}

h3 {
    text-align: center;
    margin: $s-size;
}


button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.is-active {
    font-weight: bold;
}

.is-active {
    cursor: default;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}