.header {
    background: $light-brown;
    margin-bottom: $s-size;
    padding: $m-size 0;
}

.header__title {
    font-size: $l-size;
    margin: 0;
}

.header__subtitle {
    font-size: $m-size;
    font-weight: 500;
    margin: 0;
}

.header--spacing {
    display: flex;
    justify-content: space-between;
}