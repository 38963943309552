// Colors
$brown-background: #5f5140;
$light-brown: #6a6259;
$light-green: #76b776;
$light-red: #cc8484;

//Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

//Sizes
$xs-size: 0.6rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;